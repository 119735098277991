/*
 * See: https://fonts.google.com/license/googlerestricted
 */
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Product Sans Light'), local('ProductSans-Light'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShdvPWbT2lB.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Product Sans'), local('ProductSans-Regular'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxiDypQkot1TnFhsFMOfGShVF9eO.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 500;
  src: local('Product Sans Medium'), local('ProductSans-Medium'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShd5PSbT2lB.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Product Sans Bold'), local('ProductSans-Bold'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShdrPKbT2lB.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 900;
  src: local('Product Sans Black'), local('ProductSans-Black'),
    url(https://fonts.gstatic.com/s/productsans/v10/pxicypQkot1TnFhsFMOfGShdlPCbT2lB.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
