/* List header with filtering */

.list-header {
  margin-bottom: 24px;
}

.list-header-element {
  margin-right: 24px;
}

.list-title {
  font-size: 24px;
  font-weight: 500;
  font-family: 'Eina03', Roboto, sans-serif;
}

.list-empty {
  font-size: 18px;
  text-align: center;
  margin-top: 30px;
}

.filter-input-font {
  font-size: 14px;
}

.filter-input-lg {
  width: 350px !important;
}

.filter-input {
  width: 300px;
}

.filter-input-m {
  width: 252px !important;
}

.filter-input-sm {
  width: 220px !important;
}

.filter-input-xs {
  width: 170px !important;
}

.filter-input-xxs {
  width: 120px !important;
}

.flex-direction-row {
  flex-direction: row !important;
}
