/* Side-nav group title is colored */

.nav-overview-group {
  color: $color-accent-light;
}

.nav-reports-group {
  color: $color-green;
}

.nav-sources-group {
  color: $color-violet;
}

.nav-exports-group {
  color: $color-pink;
}

.nav-enterprise {
  opacity: 40%;
}

/* Side-nav items have background-color on hover */

.nav-overview:hover {
  background-color: $color-accent-background;
  /*color: $color-accent;*/
}

.nav-reports:hover {
  background-color: $color-green-background;
  /*color: $color-green;*/
}

.nav-sources:hover {
  background-color: $color-violet-background;
  /*color: $color-violet;*/
}

.nav-exports:hover {
  background-color: $color-pink-background;
  /*color: $color-pink;*/
}

.nav-tools:hover {
  background-color: $color-gray-background;
  /*color: $color-gray*/
}

/* Side-nav items have background-color when route is active */

.color-background-nav-active {
  &.nav-overview {
    background-color: $color-accent-background;
    border-left: 5px solid $color-accent-light;
    /*color: $color-accent;*/
  }

  &.nav-reports {
    background-color: $color-green-background;
    border-left: 5px solid $color-green;
    /*color: $color-green;*/
  }

  &.nav-sources {
    background-color: $color-violet-background;
    border-left: 5px solid $color-violet;
    /*color: $color-violet;*/
  }

  &.nav-exports {
    background-color: $color-pink-background;
    border-left: 5px solid $color-pink;
    /*color: $color-pink;*/
  }

  &.nav-tools {
    background-color: $color-gray-background;
    border-left: 5px solid $color-gray;
    /*color: $color-gray*/
  }

  &.nav-item-org {
    background-color: $color-accent-background !important;
  }
}

.grayscale {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  color: gray; /* Needed for Angular Material Symbol Font */
}
