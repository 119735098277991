button.mdc-button {
  border-radius: 32px;
}

button.mat-mdc-unelevated-button {
  border-radius: 32px;
  padding-right: 20px;
}

button.mat-mdc-outlined-button {
  border-radius: 32px;
  padding-right: 20px;
}
