// Primary
@font-face {
  font-family: 'Eina03';
  src: url('../../assets/fonts/eina/Eina 03 Regular.otf') format('opentype');
  font-weight: 300 500;
}

@font-face {
  font-family: 'Eina03';
  src: url('../../assets/fonts/eina/Eina 03 Bold.otf') format('opentype');
  font-weight: bold;
}

/* Secondary, loaded from Google Fonts now
@font-face {
  font-family: 'Poppins';
  src: url('../../assets/fonts/poppins/Poppins-Regular.ttf') format("truetype");
  font-weight: 300 500;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../assets/fonts/poppins/Poppins-Bold.ttf') format("truetype");
  font-weight: bold;
}
*/

// Use secondary font by default
$pma-typography: mat-typography-config(
  $font-family: 'Poppins',
);

.font-primary {
  font-family: 'Eina03', Roboto, sans-serif;
}

.font-secondary {
  font-family: 'Poppins', Roboto, sans-serif;
}
