@import 'variables';

.main-dialog mat-dialog-container > *:first-child {
  display: flex;
  flex-direction: column;
  align-items: normal;
  max-width: 100%;
  width: 100%;

  .close-dialog {
    align-self: flex-end;
    font-size: 24px;
    margin-bottom: 8px;
  }
}

.app-loader-dialog mat-dialog-container {
  // TODO make it look nicer
}
