.mat-mdc-menu-panel.guest-menu {
  padding: 0 8px;
  background-color: rgba(0, 0, 0, 0.87);
  border-radius: 0 0 4px 4px;
}

.mat-mdc-menu-panel {
  margin-top: 14px;
  max-width: none !important;
}

.account-menu {
  text-align: center;
  padding: 0 20px;

  h6 {
    margin: 0;
  }

  h5 {
    margin: 12px;
  }
}
