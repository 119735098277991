@use '@angular/material' as mat;
@use 'sass:map';
//@import '@angular/material/theming';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$orange-palette);
$app-accent: mat.define-palette(mat.$blue-palette, A200, A100, A400);
$app-warn: mat.define-palette(mat.$red-palette);

// Define custom palettes using PMA colors (http://mcg.mbitson.com)
$app-primary-pma: (
  50: #ffeae2,
  100: #fecbb7,
  200: #fda888,
  300: #fc8558,
  400: #fc6b34,
  500: #fb5110,
  600: #fa4a0e,
  700: #fa400c,
  800: #f93709,
  900: #f82705,
  A100: #ffffff,
  A200: #ffeeec,
  A400: #ffc0b9,
  A700: #ffa9a0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$app-accent-pma: (
  50: #e0edff,
  100: #b3d1ff,
  200: #80b3ff,
  300: #00a3ff,
  400: #267dff,
  500: #0066ff,
  600: #005eff,
  700: #0053ff,
  800: #0049ff,
  900: #0055d6,
  A100: #ffffff,
  A200: #f2f4ff,
  A400: #bfc9ff,
  A700: #a6b4ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Create the theme objects (Sass maps containing all of the palettes)
$pma-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
      background: (
        dialog: red,
        card: red,
        app-bar: red,
        background: red,
      ),
    ),
  )
);
$pma-theme-light: $pma-theme;
$pma-theme-dark: mat.define-dark-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
      background: (
        dialog: red,
        card: red,
        app-bar: red,
        background: red,
      ),
    ),
  )
);

// Customize the background colors of the dark theme

$background: map-get($pma-theme-dark, background);
$background: map_merge(
  $background,
  (
    background: #15151a,
    dialog: #1d1d20,
    card: #2e2e32,
    app-bar: #030304,
  )
);
$pma-theme-dark: map.deep-merge(
  $pma-theme-dark,
  (
    background: $background,
    color: (
      background: $background,
    ),
  )
);

// Theme color variables
$color-primary: mat.get-color-from-palette($app-primary-pma, 500);
$color-primary-dark: mat.get-color-from-palette($app-primary-pma, 900);
$color-primary-light: mat.get-color-from-palette($app-primary-pma, 300);
$color-primary-background: mat.get-color-from-palette($app-primary-pma, 500, 0.1);

$color-accent: mat.get-color-from-palette($app-accent-pma, 500);
$color-accent-dark: mat.get-color-from-palette($app-accent-pma, 900);
$color-accent-light: mat.get-color-from-palette($app-accent-pma, 300);
$color-accent-background: mat.get-color-from-palette($app-accent-pma, 500, 0.1);

// Secondary color variables
$color-gray: mat.get-color-from-palette(mat.$gray-palette, 600);
$color-gray-background: mat.get-color-from-palette(mat.$gray-palette, 600, 0.1);
$color-yellow: #ffe000;
$color-green: #10bb66;
$color-green-background: #10bb661a;
$color-pink: #ff005c;
$color-pink-background: #ff005c1a;
$color-violet: #c566ff; // #9A00FF;
$color-violet-background: #c566ff1a;
$color-turquoise: #00c2ff;
$color-iron: #dee1e2;
$color-iron-background: #dee1e21a;

// Theme mixins
@mixin custom-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  tr:hover:not(.mat-mdc-header-row) {
    background-color: mat.get-color-from-palette($background, hover);
  }

  .hover-background:hover {
    background-color: mat.get-color-from-palette($background, hover);
  }

  .highlight-background {
    background-color: mat.get-color-from-palette($background, hover);
  }

  .highlight-background-transparent {
    background-color: mat.get-color-from-palette($background, hover, 0.08);

    &:hover {
      background-color: mat.get-color-from-palette($background, hover);
    }
  }

  .text-primary {
    color: mat.get-color-from-palette($foreground, text);
  }

  .text-secondary {
    color: mat.get-color-from-palette($foreground, secondary-text) !important;
  }

  .mat-mdc-header-cell {
    color: mat.get-color-from-palette($foreground, text) !important;
  }

  .mat-mdc-cell {
    color: mat.get-color-from-palette($foreground, secondary-text) !important;
  }
}
