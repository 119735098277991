@import 'variables';

table[mat-table] {
  width: 100%;
  border-collapse: collapse;
}

.table-wrapper {
  border-radius: 8px;
  overflow: hidden;
  flex-direction: initial !important;
}

#table-columns {
  th.mat-mdc-header-cell {
    font-size: 14px;
    font-weight: 500;
  }
}
