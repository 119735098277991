.mat-mdc-tooltip {
  font-size: 13px !important;
}

.explanation-tooltip {
  font-size: 13px;
}

.explanation-tooltip-no-break {
  font-size: 13px;
  max-width: 600px !important;
  word-break: break-all;
  white-space: normal;
}

.explanation-tooltip-break-newline {
  font-size: 16px;
  white-space: pre !important;
}

.explanation-tooltip-multiline {
  font-size: 16px;
  white-space: pre-line !important;
}
