@use '@angular/material' as mat;

// AG Grid theming

@import 'ag-grid-community/src/styles/ag-grid.scss';
@import 'ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss';

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      odd-row-background-color: #cfd8dc,
    )
  );
}

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

/* You can add global styles to this file, and also import other style files */
@import '../assets/fonts/product-sans/style.css';
@import 'overrides/cards';
@import 'overrides/menus';
@import 'overrides/inputs';
@import 'overrides/tables';
@import 'overrides/dialogs';
@import 'overrides/buttons';
@import 'variables';
@import 'shared/dialog';
@import 'shared/tooltip';
@import 'shared/nav';
@import 'shared/nav-side';
@import 'shared/list';
@import 'shared/font';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@include mat.core-typography($pma-typography);
@include mat.all-component-typographies($pma-typography);
@include mat.all-component-densities($pma-theme);

html,
.light-mode {
  @include mat.all-component-colors($pma-theme-light);
  @include custom-theme($pma-theme-light);
}

.dark-mode {
  @include mat.all-component-colors($pma-theme-dark);
  @include custom-theme($pma-theme-dark);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Poppins', Roboto, sans-serif;
}

app-root {
  height: 100%;
}

h6 {
  color: mat.get-color-from-palette($app-accent-pma, 900, 0.87);
  font-weight: 500;
  font-size: 24px;
  margin: 0 0 32px;
}

.container {
  display: flex;
  box-sizing: border-box;
  margin: auto;
  padding: 0 32px;
  width: 100%;
}

.container-vertical {
  @extend .container;
  flex-direction: column;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.padding-8 {
  padding: 16px;
}

.padding-16 {
  padding: 16px;
}

.padding-24 {
  padding: 32px;
}

.padding-32 {
  padding: 32px;
}

.padding-64 {
  padding: 32px;
}

.padding-128 {
  padding: 32px;
}

.align-center {
  display: flex;
  justify-content: center;
}

.align-end {
  justify-content: flex-end;
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.hidden {
  display: none !important;
}

/* Theme colors */

.color-primary {
  color: $color-primary;
}

.color-primary-dark {
  color: $color-primary-dark;
}

.color-primary-light {
  color: $color-primary-light;
}

.color-accent {
  color: $color-accent !important;
}

.color-accent-dark {
  color: $color-accent-dark !important;
}

.color-accent-light {
  color: $color-accent-light !important;
}

.color-warn {
  color: mat.get-color-from-palette($app-warn, 800);
}

.color-background-warn {
  background-color: mat.get-color-from-palette($app-warn, 800);
}

.color-background-primary {
  background-color: $color-primary-background !important;
}

.color-background-accent {
  background-color: $color-accent-background !important;
}

/* Secondary colors */

.color-yellow {
  color: $color-yellow;
}

.color-green {
  color: $color-green;
}

.color-pink {
  color: $color-pink;
}

.color-violet {
  color: $color-violet;
}

.color-turquoise {
  color: $color-turquoise;
}

.color-iron {
  color: $color-iron;
}

.color-gray {
  color: $color-gray;
}

.color-gray-light {
  color: lightgray;
}

/* Flex layout */

.flex-row-between {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 24px 16px;
}

.flex-row-between-nowrap {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px 16px;
}

.flex-row-start {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  gap: 24px 16px;
}

.flex-row-start-nowrap {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
}

.flex-row-end {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  gap: 24px 16px;
}

.flex-row-end-nogap {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}

.flex-row-align-center {
  align-items: center;
}

.flex-row-align-start {
  align-items: flex-start;
}

.flex-row-align-end {
  align-items: flex-end;
}

.flex-row-align-baseline {
  align-items: baseline;
}

.spacer {
  width: 10px;
}

.billing-card {
  padding: 20px;

  h2 {
    font-weight: 400;
  }
}

.auth-avatar {
  border-radius: 100%;
  border: 3px solid white;
  box-sizing: border-box;
  cursor: pointer;
  width: 36px;
  height: 36px;
  overflow: hidden;

  :focus {
    outline: 0;
  }

  &.large {
    width: 72px;
    height: 72px;
  }

  &.borderless {
    border-style: none;
  }
}

.header-message {
  font-size: 16px;
  margin-bottom: 16px;
}

.note-margin {
  margin-bottom: 24px;
}

/* Buttons */

.button-warn:not([disabled]) {
  color: white !important;
  background-color: mat.get-color-from-palette($app-warn, 500) !important;

  &:hover {
    background-color: mat.get-color-from-palette($app-warn, 900) !important;
  }
}

.button-standard:not([disabled]) {
  color: white !important;
  background-color: $color-accent !important;

  &:hover {
    background-color: $color-accent-dark !important;
  }
}

.button-icon {
  font-size: 22px;
  margin-right: 6px;
}

.button-large {
  height: 50px;
}

.button-dialog-close {
  cursor: pointer;
}

/* Links */

.hyperlink {
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.hyperlink-no-underline {
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
}

.dark-mode .hyperlink {
  color: $color-accent-light;
}

.light-mode .hyperlink {
  color: $color-accent;
}

.dark-mode .hyperlink-no-underline {
  color: $color-accent-light;
}

.light-mode .hyperlink-no-underline {
  color: $color-accent;
}

.hyperlink-offset {
  margin-left: -10px;
}

/* Scrollbars */

* {
  scrollbar-width: thin;
  scrollbar-color: $color-gray;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: gray;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.5);
}

.close-icon-button {
  .dark-mode & {
    color: white;
  }
}

.link {
  .dark-mode & {
    color: white;
  }
  .light-mode & {
    color: black;
  }
}
