@import 'variables';

/* Prevent resizing toolbar to height of 56px on very small screen widths */
::ng-deep .mat-toolbar-row,
.mat-toolbar-single-row {
  height: 64px !important;
}

/* Nav items routing */

.nav-item-routed {
  padding: 6px 0 6px 0;
  cursor: pointer;
}

.nav-item-group-routed {
  padding: 10px 0 10px 0;
  cursor: pointer;
}

/* Nav group icons and images */

.nav-icon-group {
  font-size: 24px;
  width: initial !important;
  height: initial !important;
  margin-right: 16px;
}

.nav-icon-image-group {
  width: 20px !important;
  height: 20px !important;
  margin-right: 16px;
}

/* Nav icons and images */

.nav-icon {
  font-size: 24px;
  width: initial !important;
  height: initial !important;
  margin-right: 16px;
}

.nav-icon-image {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.nav-button-font {
  font-size: 18px;
}

.nav-button-offset {
  position: relative;
  top: -1px;
}

.nav-tooltip {
  font-size: 16px;
}

.nav-space {
  width: 14px;
}
